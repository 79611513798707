/**
 * Created by r4zi4l on 04.07.2022
 */

OrderIcon.prototype.updateIngredients = function () {

};

OrderIcon.prototype.updateButton = function () {

};

OrderIcon.prototype.updateRewards = function () {
    if (!this.maker.recipe || this.rewards) {
        return;
    }

    var styles = cleverapps.styles.OrderIcon.rewards;
    var units = {};

    this.maker.recipe.prize.forEach(function (unit) {
        var key = Unit.GetKey(unit);

        if (!units[key]) {
            units[key] = 0;
        }

        units[key] += 1;
    });

    var icons = [];

    Object.keys(units).forEach(function (key) {
        var unit = Unit.ParseKey(key);
        icons.push(UnitView.getUnitImage(unit, { preferStatic: true }));
        icons.push(cleverapps.UI.generateImageText("x" + units[key], cleverapps.styles.FONTS.ORDERICON_AMOUNT_TEXT));
    });

    this.rewards = new cleverapps.GridLayout(icons, {
        columns: 2,
        margin: styles.margin
    });
    this.rewards.setPositionRound(styles);
    this.rewards.setCascadeOpacityEnabledRecursively(true);
    this.unitsLayer.addChild(this.rewards);

    cleverapps.UI.fitToBox(this.rewards, {
        height: styles.height
    });

    this.rewards.setOpacity(0);
    this.rewards.runAction(this.showAction.clone());
};

OrderIcon.prototype.updateSelection = function (maker) {
    if (this.maker === maker) {
        this.background.interactiveScale = false;
        this.background.setSpriteFrame(bundles.ordericon.frames.order_sheet_selected);

        if (this.clickHandler) {
            this.clickHandler.remove();
            delete this.clickHandler;

            this.hoverHandler.remove();
            delete this.hoverHandler;
        }
    } else {
        this.background.interactiveScale = true;
        this.background.setSpriteFrame(bundles.ordericon.frames.order_sheet);
        this.background.applyInteractiveScale();

        if (!this.clickHandler) {
            this.clickHandler = cleverapps.UI.onClick(this, function () {
                cleverapps.audio.playSound(bundles.main.urls.click_effect);
                Game.currentGame.orders.selectMaker(this.maker);
            }.bind(this));

            this.hoverHandler = cleverapps.UI.applyHover(this);
        }
    }
};

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    ORDERICON_TIMER_TEXT: {
        name: "default",
        size: 28,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    }
});

cleverapps.overrideStyles(cleverapps.styles.OrderIcon, {
    width: 260,
    height: 300,
    rotation: -3,

    pin: {
        x: { align: "left", dx: 0 },
        y: { align: "top", dy: 4 },
        icon: {
            x: { align: "center" },
            y: { align: "center", dy: 25 }
        }
    },

    tape: {
        x: { align: "center", dx: 0 },
        y: { align: "top", dy: 10 }
    },

    timer: {
        x: { align: "left", dx: -14 },
        y: { align: "top", dy: 2 },
        width: 260,

        background: {
            x: { align: "left", dx: 14 },
            y: { align: "center", dy: -5 },
            width: 260
        }
    },

    rewards: {
        x: { align: "center" },
        y: { align: "center", dy: -15 },
        height: 200,

        margin: {
            x: 0,
            y: 15
        }
    }
});
