/**
 * Created by andrey on 13.01.2021.
 */

cleverapps.override(cleverapps.styles.UnitAnimations, {
    "worker": {
        "stage5": {
            idle: 1000
        }
    },
    "dwarf": {
        "stage2": {
            animation: 100,
            animation2: 100,
            idle: 800
        }
    },
    "king": {
        "stage4": {
            animation: 800,
            animation2: 100,
            animation3: 100
        }
    },
    "bear": {
        "stage7": {
            animation1: 100,
            animation2: 100,
            idle: 800
        }
    },
    "elf": {
        "stage8": {
            animation: 100,
            animation2: 100,
            idle: 800
        }
    },
    "warlock": {
        "stage9": {
            animation: 100,
            animation2: 100,
            idle: 800
        }
    },
    "troll": {
        "stage10": {
            animation: 100,
            animation2: 100,
            idle: 800
        }
    },
    "knight": {
        "stage11": {
            animation: 100,
            idle: 900
        }
    },
    "hunter": {
        "stage6": {
            animation: 100,
            idle: 900
        },
        "stage7": {
            animation: 100,
            idle: 900
        },
        "stage8": {
            animation: 100,
            idle: 900
        },
        "stage9": {
            animation: 100,
            idle: 900
        },
        "stage10": {
            animation: 100,
            animation2: 100,
            idle: 800
        }
    },
    "wands": {
        "stage1": {
            animation: 100,
            animation2: 50,
            animation3: 50,
            idle: 3600
        },
        "stage2": {
            animation: 100,
            idle: 1800
        },
        "stage3": {
            animation: 100,
            idle: 1800
        },
        "stage4": {
            animation: 100,
            idle: 1800
        },
        "stage5": {
            animation: 100,
            idle: 1800
        },
        "stage6": {
            idle: 1000
        }
    },
    "woodgenerator": {
        "stage1": {
            animation: 100,
            idle: 1000
        },
        "stage2": {
            animation: 100,
            idle: 1000
        },
        "stage3": {
            animation: 100,
            idle: 1000
        }
    },
    "stonegenerator": {
        "stage1": {
            animation: 100,
            idle: 1000
        },
        "stage2": {
            animation: 100,
            idle: 1000
        },
        "stage3": {
            animation: 100,
            idle: 1000
        }
    },
    "wagongenerator": {
        "stage1": {
            animation: 100,
            idle: 1000
        },
        "stage2": {
            animation: 100,
            idle: 1000
        },
        "stage3": {
            animation: 100,
            idle: 1000
        }
    },
    "bamboogenerator": {
        "stage1": {
            animation: 100,
            idle: 1000
        },
        "stage2": {
            animation: 100,
            idle: 1000
        },
        "stage3": {
            animation: 100,
            idle: 1000
        }
    },
    "sapphiregenerator": {
        "stage1": {
            animation: 100,
            idle: 1000
        },
        "stage2": {
            animation: 100,
            idle: 1000
        },
        "stage3": {
            animation: 100,
            idle: 1000
        }
    }
});
